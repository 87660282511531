<template>
    <div  class="table" ref="tableContainer" style="contain: content; overflow-x: scroll ;">
        <div :style="{ width: tableWidth }" class="table-container">
            <div :style="{ width: '100%' }" v-if="data.length > 0">
              <v-virtual-scroll v-if="data.length > 0" :bench="15" :items="data" :max-height="calculatedHeight" min-height="27.5" :width="'100%'" item-height="27.5" class="px-1">
              <template v-slot:default="{ index, item }">
                <div  class="text-center my-0 py-0" :style="{display: 'flex','flex-direction': 'row',width: '100%','background-color' : Math.abs(index % 2) == 1 ? '' : 'var(--v-greyDarker-base)' ,cursor: 'pointer'}">
                    <td v-for="(header, index) in headers.filter(x => x.show)" :key="index" :style="{ width: header.width ?? '150px', 'font-size': '11px', }" class="text-center mt-0 mb-0 pb-0 tableCell" @contextmenu="viewItem($event, item, header.value)">
                        <div v-if="header.value == 'shippingLineName'" class="text-left">
                          <v-tooltip top v-if="item.shippingLineName" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-on="on" v-if="item.shippingLine" small class="primaryText--text" outlined style="border: none">
                                <v-avatar size="24" :color="item.shippingLine && item.shippingLine.logo ? 'white' : 'secondary'" left>
                                  <v-img v-if="item.shippingLine.logo" :src="item.shippingLine.logo" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.shippingLineName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.shippingLine?.alias ? item.shippingLine.alias : item.shippingLine?.name ? item.shippingLine.name : ' - '}}
                              </v-chip>
                            </template>
                            <span>{{ item.shippingLineName }}</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'vesselVoyage'" class="text-left">
                          <v-tooltip top v-if="item.vesselVoyage" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-on="on" small class="primaryText--text" outlined style="border: none; font-size: 11px">
                                <v-icon small left v-if="item.isTransshipment" color="orange" >airline_stops</v-icon>
                                <v-icon v-else small left color="grey">navigation</v-icon>
                                <span>{{ item.vesselVoyage }}</span>
                              </v-chip>
                            </template>
                            <span>{{ item.vesselVoyage }}: {{item.isTransshipment ? 'Transshipment' : 'Direct'}}</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'originCountryName'" class="text-center tooltip-container">
                            <v-tooltip top v-if="item.originCountryValue" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip outlined style="border: none; font-size: 11px" v-on="on" small>
                                <v-avatar size="20" left>
                                  <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.originCountryValue.substring(0, 2).toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.originCountryValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px"><b>{{item.originCountryName}}</b></span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'portOfLoadValue'" class="text-center tooltip-container">
                            <v-tooltip top v-if="item.portOfLoadValue" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip outlined style="border: none; font-size: 11px" v-on="on" small>
                                <v-avatar size="20" left>
                                  <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.portOfLoadValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px"><b>{{getCountryName(item.portOfLoadValue)}}</b>{{item.portOfLoadCity}}</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'portOfDischargeValue'" class="text-center">
                          <v-tooltip top v-if="item.portOfDischargeValue" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip outlined style="border: none; font-size: 11px" v-on="on" small>
                                <v-avatar size="20" left>
                                  <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfDischargeValue.substring(0, 2).toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.portOfDischargeValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px"><b>{{getCountryName(item.portOfDischargeValue)}}</b>{{item.portOfDischargeCity}}</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'finalDestinationValue'" class="text-center">
                          <v-tooltip top v-if="item.finalDestinationValue" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip outlined style="border: none; font-size: 11px" v-on="on" small>
                                <v-avatar size="20" left>
                                  <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.finalDestinationValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px"><b>{{getCountryName(item.finalDestinationValue)}}</b>{{item.finalDestinationCity}}</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'shipperName'" class="text-left">
                            <v-tooltip top v-if="item.shipper" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="item.shipper" small class="primaryText--text" v-on="on" outlined style="border: none">
                                <v-avatar size="20" :color=" item.shipper && item.shipper.logo ? 'white' : 'secondary' " left>
                                  <v-img v-if="item.shipper.logo" :src="item.shipper.logo" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.shipperName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span>{{ item.shipper?.alias ? item.shipper.alias : item.shipper?.name ? item.shipper.name : ' - ' }}</span>
                              </v-chip>
                            </template>
                            <span>{{ item.shipper.name }}</span>
                          </v-tooltip>
                          <span v-else>{{' - '}}</span>
                        </div>
                        <div v-else-if="header.value == 'buyerName'" class="text-left">
                            <v-tooltip top v-if="item.buyer" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="item.buyer" small class="primaryText--text" v-on="on" outlined style="border: none">
                                <v-avatar size="20" :color="item.buyer && item.buyer.logo? 'white': 'secondary'" left>
                                  <v-img v-if="item.buyer.logo" :src="item.buyer.logo" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.buyerName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span>{{ item.buyer?.alias ? item.buyer.alias : item.buyer?.name ? item.buyer.name : ' - ' }}</span>
                              </v-chip>
                            </template>
                            <span>{{ item.buyer.name }}</span>
                          </v-tooltip>
                          <span v-else>{{' - '}}</span>
                        </div>
                        <div v-else-if="header.value == 'forwarderName'" class="text-left">
                            <v-tooltip top v-if="item.forwarder" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="item.forwarder" small class="primaryText--text" v-on="on" outlined style="border: none">
                                <v-avatar size="20" :color="item.forwarder && item.forwarder.logo? 'white': 'secondary'" left>
                                  <v-img v-if="item.forwarder.logo" :src="item.forwarder.logo" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.forwarderName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span>{{ item.forwarder?.alias ? item.forwarder.alias : item.forwarder?.name ? item.forwarder.name : ' - ' }}</span>
                              </v-chip>
                            </template>
                            <span>{{ item.forwarder.name }}</span>
                          </v-tooltip>
                          <span v-else>{{' - '}}</span>
                        </div>
                        <div v-else-if="header.value == 'consigneeName'" class="text-left">
                            <v-tooltip top v-if="item.consignee" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="item.consignee" small class="primaryText--text" v-on="on" outlined style="border: none">
                                <v-avatar size="20" :color="item.consignee && item.consignee.logo? 'white': 'secondary'" left>
                                  <v-img v-if="item.consignee.logo" :src="item.consignee.logo" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.consigneeName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span>{{ item.consignee?.alias ? item.consignee.alias : item.consignee?.name ? item.consignee.name : ' - ' }}</span>
                              </v-chip>
                            </template>
                            <span>{{ item.consignee.name }}</span>
                          </v-tooltip>
                          <span v-else>{{' - '}}</span>
                        </div>

                        <div v-else-if="header.value == 'onBehalfShipperName'" class="text-left">
                            <v-tooltip top v-if="item.onBehalfShipper" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="item.onBehalfShipper" small class="primaryText--text" v-on="on" outlined style="border: none">
                                <v-avatar size="20" :color="item.onBehalfShipper && item.onBehalfShipper.logo? 'white': 'secondary'" left>
                                  <v-img v-if="item.onBehalfShipper.logo" :src="item.onBehalfShipper.logo" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.onBehalfShipperName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span>{{ item.onBehalfShipper?.alias ? item.onBehalfShipper.alias : item.onBehalfShipper?.name ? item.onBehalfShipper.name : ' - ' }}</span>
                              </v-chip>
                            </template>
                            <span>{{ item.onBehalfShipper.name }}</span>
                          </v-tooltip>
                          <span v-else>{{' - '}}</span>
                        </div>
                        <div v-else-if="header.value == 'onBehalfForwarderName'" class="text-left">
                            <v-tooltip top v-if="item.onBehalfForwarder" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="item.onBehalfForwarder" small class="primaryText--text" v-on="on" outlined style="border: none">
                                <v-avatar size="20" :color="item.onBehalfForwarder && item.onBehalfForwarder.logo? 'white': 'secondary'" left>
                                  <v-img v-if="item.onBehalfForwarder.logo" :src="item.onBehalfForwarder.logo" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.onBehalfForwarderName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span>{{ item.onBehalfForwarder?.alias ? item.onBehalfForwarder.alias : item.onBehalfForwarder?.name ? item.onBehalfForwarder.name : ' - ' }}</span>
                              </v-chip>
                            </template>
                            <span>{{ item.onBehalfForwarder.name }}</span>
                          </v-tooltip>
                          <span v-else>{{' - '}}</span>
                        </div>
                        <div v-else-if="header.value == 'onBehalfBuyerName'" class="text-left">
                            <v-tooltip top v-if="item.onBehalfBuyer" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="item.onBehalfBuyer" small class="primaryText--text" v-on="on" outlined style="border: none">
                                <v-avatar size="20" :color="item.onBehalfBuyer && item.onBehalfBuyer.logo? 'white': 'secondary'" left>
                                  <v-img v-if="item.onBehalfBuyer.logo" :src="item.onBehalfBuyer.logo" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.onBehalfBuyerName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span>{{ item.onBehalfBuyer?.alias ? item.onBehalfBuyer.alias : item.onBehalfBuyer?.name ? item.onBehalfBuyer.name : ' - ' }}</span>
                              </v-chip>
                            </template>
                            <span>{{ item.onBehalfBuyer.name }}</span>
                          </v-tooltip>
                          <span v-else>{{' - '}}</span>
                        </div>
                        <div v-else-if="header.value == 'onBehalfConsigneeName'" class="text-left">
                            <v-tooltip top v-if="item.onBehalfConsignee" open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="item.onBehalfConsignee" small class="primaryText--text" v-on="on" outlined style="border: none">
                                <v-avatar size="20" :color="item.onBehalfConsignee && item.onBehalfConsignee.logo? 'white': 'secondary'" left>
                                  <v-img v-if="item.onBehalfConsignee.logo" :src="item.onBehalfConsignee.logo" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.onBehalfConsigneeName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span>{{ item.onBehalfConsignee?.alias ? item.onBehalfConsignee.alias : item.onBehalfConsignee?.name ? item.onBehalfConsignee.name : ' - ' }}</span>
                              </v-chip>
                            </template>
                            <span>{{ item.onBehalfConsignee.name }}</span>
                          </v-tooltip>
                          <span v-else>{{' - '}}</span>
                        </div>
                        <div v-else-if="header.value == 'features'" class="text-center">
                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Genset' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#D6D46D" >
                                <v-icon small>bolt</v-icon>
                              </v-btn>
                            </template>
                            <span>Genset Required</span>
                          </v-tooltip>

                          <v-tooltip
                            top v-if=" item.temporaryProducts.some( (product) => product.product === 'Fumigation' ) " open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#F1DBBF" >
                                <v-icon small>cleaning_services</v-icon>
                              </v-btn>
                            </template>
                            <span>Fumigations Required</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Void Plugs' ) " open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#e05b0d">
                                <v-icon small>power</v-icon>
                              </v-btn>
                            </template>
                            <span>Void Plugs</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Blast Freezing' ) " open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#13a7d4">
                                <v-icon small>ac_unit</v-icon>
                              </v-btn>
                            </template>
                            <span>Blast Freezing</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Monitoring Service' ) " open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#99B080">
                                <v-icon small>desktop_windows</v-icon>
                              </v-btn>
                            </template>
                            <span> {{ item.temporaryProducts.find( (product) => product.product === "Monitoring Service").variation + " " + "Service" + " " + "Required"}}
                            </span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'ContainerLiners' ) " open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#EEF296">
                                <v-icon small>settings_overscan</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{ "Container Liners" + " " + "Required" }}
                            </span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'SmartFresh' ) " open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#FFBFFF">
                                <v-icon small>smart_screen</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{ "Smart Fresh" + " " + "Required" }}
                            </span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'SteriInsurance' ) " open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#EEF296">
                                <v-icon small>emergency</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{ "Steri Insurance" + " " + "Required" }}
                            </span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'TelemPlus' ) " open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#18e00d">
                                <v-icon small>router</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              Devices Required:
                              <template v-if=" item.temporaryProducts.some( (product) => product.product === 'TelemPlus' )" open-delay="1000">
                                <ul>
                                  <li :key="index" v-for="([variation, count], index) in Object.entries(
                                    item.temporaryProducts.filter((product) =>product.product === 'TelemPlus').reduce((acc, product) => {
                                    if (!acc[product.variation]) {acc[product.variation] = 0;} 
                                    acc[product.variation] += product.count;return acc;}, {}))" >
                                    {{ count }}X{{ variation }}
                                  </li>
                                </ul>
                              </template>
                            </span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Otflow' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#6A9C89">
                                <v-icon small>air</v-icon>
                              </v-btn>
                            </template>
                            <span>Otflow Required</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Zoono' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#5b93c7">
                                <v-icon small>sanitizer</v-icon>
                              </v-btn>
                            </template>
                            <span>Zoono Required</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Dunnage Bags' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#F1DBBF">
                                <v-icon small>shopping_bag</v-icon>
                                </v-btn>
                            </template>
                            <span>Dunnage Bags Required</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Late Arrival' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#BB2525">
                                <v-icon small>watch_later</v-icon>
                                </v-btn>
                            </template>
                            <span>
                              Late Arrival Required :
                              {{item.temporaryProducts.find((product) => product.product === "Late Arrival").variation}}
                            </span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Latches' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#508D69">
                                <v-icon small>pivot_table_chart</v-icon>
                                </v-btn>
                            </template>
                            <span>Latches Required</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Ratches' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#EA906C" >
                                <v-icon small>settings_input_component</v-icon>
                              </v-btn>
                            </template>
                            <span>Ratches Required</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Stabling bars' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#5F6F52">
                                <v-icon small>video_stable</v-icon>
                                </v-btn>
                            </template>
                            <span>Stabling bars Required</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Kraft paper' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#C5E898">
                                <v-icon small>note</v-icon>
                                </v-btn>
                            </template>
                            <span>Kraft paper Required</span>
                          </v-tooltip>

                          <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Absorb Bags' ) "  open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn icon outlined x-small class="mx-1" v-on="on" color="#DCBFFF">
                                <v-icon small>backpack</v-icon>
                                </v-btn>
                            </template>
                            <span>Absorb Bags Required</span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'stockAllocationStatus'">
                          <v-tooltip top open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" v-if="!item.totalPallets && item.allocatedPallets" color="danger">error_outline</v-icon>
                              <v-progress-circular
                                v-else-if="item.totalPallets && item.allocatedPallets"
                                v-on="on"
                                :value="item.allocatedProgress"
                                size="20"
                                :color="
                                  item.totalPallets == 0
                                    ? 'grey'
                                    : item.allocatedProgress < 20
                                    ? 'red'
                                    : item.allocatedProgress < 50
                                    ? 'orange'
                                    : item.allocatedProgress < 100
                                    ? 'blue'
                                    : 'success'
                                "
                              >
                              </v-progress-circular>
                            </template>
                            <span v-if="!item.totalPallets && item.allocatedPallets">
                              {{ item.allocatedPallets }} Pallet{{ item.allocatedPallets > 1 ? 's have' : 'has' }} been Allocated, but no Total Pallets has been set.
                            </span>
                            <span v-else-if="item.totalPallets && item.allocatedPallets">
                              Allocated: {{ item.allocatedPallets }}/{{
                                item.totalPallets
                              }}
                              </span>
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'product'">
                          <v-tooltip right open-delay="2000">
                            <template v-slot:activator="{ on }">
                              <div v-on="on" style="height: 100%; width: 100%;">
                                  <v-chip v-for="(product, index) in item.products" :key="index" class="px-2" small>
                                    {{ product ? product.code ?? product.name : 'N/A' }}
                                  </v-chip>
                              </div>
                            </template>
                              <li v-for="(product, index) in item.products" :key="index" style="font-size: 11px">
                                {{product.name ? $Format.capitalizeFirstLetter(product.name): ""}}
                              </li>
                          </v-tooltip>
                        </div>

                        <div v-else-if="header.value == 'fileNumber'" class="cellContent" @click="item.fileNumber ? viewShipment(item, 'shipment') : ''">
                          {{ item.fileNumber ?? ' - ' }}
                        </div>

                        <div v-else-if="header.value == 'fileStatus'" class="cellContent">
                          <v-tooltip top open-delay="2000" v-if="$store.state.currentOrg.id == item.shipper?.id || $store.state.currentOrg.id == item.consignee?.id || $store.state.currentOrg.id == item.buyer?.id">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" :color="!item.fileStatus || !['Ready', 'Sent'].includes(item.fileStatus) ? '#9e9e9e3b' : item.fileStatus == 'Ready' ? 'tertiary' : item.fileStatus == 'Sent' ? 'primary' : '#9e9e9e3b'">
                              {{ !item.fileStatus ?  'folder_off' : item.fileStatus == 'Ready' ? 'forward_to_inbox' : item.fileStatus == 'Sent' ? 'mark_email_read' : 'folder_off'}}
                            </v-icon>
                            </template>
                            {{ item.fileStatus == 'Ready' ? 'Ready to Send' : item.fileStatus == 'Sent' ? 'Sent' : 'Not Ready' }}
                          </v-tooltip>
                          <span v-else-if="$store.state.currentOrg.id == item.forwarder.id">
                          
                          </span>
                        </div>
                        <div v-else-if="header.value == 'serviceType'" class="cellContent">
                          <v-chip outlined style="border:none" small>{{!item.serviceType ? ' - ' : item.serviceType == 'BREAKBULK' ? 'B/B' : item.serviceType}}</v-chip>
                        </div>
                        <div v-else-if="header.value == 'eta'" class="cellContent">
                            <span>{{ $Format.formatDate(item.eta).isoFormat }}</span>
                        </div>
                        <div v-else-if="header.value == 'etd'" class="cellContent">
                            <span>{{ $Format.formatDate(item.etd).isoFormat }}</span>
                        </div>
                        <div v-else-if="header.value" class="cellContentLeft"  @click="otherActions(header.value, item)">
                            {{ item[header.value] ?? '-'}}
                        </div>                    
                      </td>
                </div>
              </template>
            </v-virtual-scroll>
            </div>
        </div>
        <v-menu v-if="selectedContainer" v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
          <v-card style="min-width: 10vw; background: var(--v-greyDarker-base) !important">
            <v-card-text class="pa-2">
              <v-list dense class="pa-0"> 
                <v-list-item @click="viewShipment(selectedContainer, 'order')" dense class="context">
                  <v-list-item-avatar small>
                    <v-icon small style="color: var(--v-greyDarker-lighten3) !important">open_in_new</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>View Order</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="viewShipment(selectedContainer, 'container')" dense class="context">
                  <v-list-item-avatar small>
                    <v-icon small style="color: var(--v-greyDarker-lighten3) !important">open_in_new</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>View Container</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item @click="viewShipment(selectedContainer, 'order')" dense class="context">
                  <v-list-item-avatar small>
                    <v-icon small style="color: var(--v-greyDarker-lighten3) !important">open_in_new</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>View Shipment</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
                <v-divider class="my-2"></v-divider>
                <v-list-item @click="copyContent()" dense class="context">
                  <v-list-item-avatar small>
                    <v-icon small style="color: var(--v-greyDarker-lighten3) !important">content_copy</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Copy</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>    
        </v-menu>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            required: false
        },
        headers: {
            type: Array,
            required: true
        },
        headerHeight: {
            type: String,
            required: false,
            default: '30px'
        },
    },
    data: () => ({
        x: 0,
        y: 0,
        showMenu: false,
        columnDrag: false,
        searchKey: 0,
        filters: {},
        files: [],
        searchHeaders: {},
        headerClicked: null,
        selectedContainer: null,
        selectAllCheck: false,
        childHeaders: [],
        resizing: false,
        initialX: 0,
        initialWidth: 0,
        currentIndex: null,
        updateKeys: 776,
        minWidth: 50,
    }),
    watch: {
        headers: {
            immediate: true,
            handler(val) {
                this.setHeaders();
            }
        },
        updateKeys: {
          handler(val) {
              this.baseKey++
              this.primaryKey++
              this.secondaryKey++
              this.tertiaryKey++
          }
        },
    },
    computed: {
        tableWidth() {
            let result = 0;
            let childHeaders = []
            if(Array.isArray(this.childHeaders)) {
              childHeaders = this.childHeaders
            } else if(typeof this.childHeaders === 'string') {
              childHeaders = JSON.parse(this.childHeaders)
            } else {
              this.$message.error('An Error occurred. Please refresh the page. If this error persists, please log a ticket with our Development team.')
            }
            this.childHeaders.filter(x => x.show).forEach((x) => {
                result += parseInt(x.width ? x.width.replace("px", "") : 150);
            });
            return result + "px";
        },
        calculatedHeight() {
            let height = window.innerHeight;
            let maxHeight = height - 36 - parseInt(this.headerHeight.replace("px", "")) + "px";
            return maxHeight;
        },
    },
    methods: {
      viewItem(e, item, header) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.selectedContainer = item
        this.headerClicked = header
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      copyContent() {
        let text = this.selectedContainer[this.headerClicked]
        navigator.clipboard.writeText(text)
        this.$message.success('Copied to clipboard')
      },
      viewShipment(item, type) {
            if(item.shipmentReference || item.systemReference) {
              sessionStorage.setItem('tradingItem', JSON.stringify(item));
              type ? sessionStorage.setItem('tradingType', type) : sessionStorage.setItem('tradingType', 'shipment'); 
              if(type === 'container') {
                sessionStorage.setItem('tradingType', 'order')
                let containerId = this.selectedContainer.id
                let obj = {
                  tab: 'overview',
                  shortcut: 'container',
                  id: containerId
                }
                sessionStorage.setItem('TradingShortcut', JSON.stringify(obj))
              } else {
                sessionStorage.removeItem('TradingShortcut')
              }
              this.$router.push({
              path: "/trading/summary/" + (type === 'shipment' ? item.shipmentReference : item.systemReference),
            });
          } else {
            this.$message.error('No Shipment Reference')
          }
      },
      otherActions(headerValue, item) {
        if(headerValue == 'orderNo') {
          this.viewShipment(item, 'order')
        }
      },
      setHeaders() {
        if(typeof this.headers === 'string') {
          let headers = JSON.parse(this.headers);
          if(Array.isArray(headers)) {
            this.headers = headers
          }
        }
          if(JSON.stringify(this.childHeaders) != JSON.stringify(this.headers)) {
              this.childHeaders = JSON.parse(JSON.stringify(this.headers));
          }
      },
      getCountryName(countryCode) {
          let country = null
          country = this.$Format.getCountryDetail({countryCode: countryCode.slice(0, 2)})
          if(country) {
              return country.name + ' - '
          } else {
              return ''
          }
      },
    }
}
</script>

<style scoped>
.no-select {
    user-select: none;
}
.resizeSide {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 80%;
    cursor: col-resize;
    background-color: transparent;
    border-radius: 4px;
    z-index: 200;
}
.resizeSide:hover {
    background-color: var(--v-greyBase-lighten2);
}

.tableHeaderCell {
    contain: content;
    cursor: grab;
    overflow-x: hidden;
    align-items: start;
    min-height: 20px !important;
    max-width: 300px !important;
    border-bottom: 1px solid var(--v-greyRaised-lighten2) !important;
}
.tableCell {
    contain: content;
    cursor: pointer;
    min-height: 20px !important;
    max-width: 300px !important;
    border-bottom: 1px solid #1414147b !important;
}
.menuList .v-list-item{
  font-size: 12px;
  min-height: 30px;
  padding: 0;
}
.menuList ::v-deep .v-icon {
  color: #989898 !important;
}

.cellContentLeft {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 8px
}
.cellContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableHeaderCell:hover, .tableCell:hover {
    background-color: var(--v-greyRaised-lighten1) !important;
}

.tableHeaderCell:hover .sortButton {
    visibility: visible;
}

.sortButton {
    visibility: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.tooltip-container {
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
  width: auto;
  height: auto;
  border-radius: 6px;
  font-size: 17px;
}
.tooltip {
  position: absolute;
  top: -39px; 
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  background: var(--v-greyRaised-lighten1) !important;
  color: white; 
  border-radius: 5px;
  width: max-content;
  height: 30px;
  font-size: 13px;
  text-align: center;
}
.tooltip::before {
  position: absolute;
  content: "";
  height: 0.6em;
  width: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  background: var(--v-greyRaised-lighten1) !important
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.context.v-list-item {
    min-height: 28px;
    height: 28px;
    padding: 0 0px !important;
}
.context ::v-deep .v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;

}
.context ::v-deep .v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.context ::v-deep .v-list-item__title {
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
}

.context ::v-deep .v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}
</style>